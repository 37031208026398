/*
Copyright AySay Broadcast b.v.
*/


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Button from '@material-ui/core/Button';
import { LanguageContext } from '../providers/Language';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';


import nl_flag from '../static/nl.png';
import en_flag from '../static/en.png';

const useStyles = makeStyles((theme) => ({
  langSelectButton: {
    background: theme.overrides.MuiAppBar.colorPrimary.backgroundColor,
    color: theme.overrides.MuiAppBar.colorPrimary.color,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    marginLeft: theme.spacing(1),
  },
  icon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  menuListIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

const LanguageSelectorButton = ( props ) => {

  const languageContext = React.useContext(LanguageContext);

  const classes = useStyles();

  const [ anchor, setAnchor ] = React.useState( null );
  const [ selectedLanguage, setSelectedLanguage ] = React.useState( languageContext.current );
  const handleOpen = event => setAnchor(event.currentTarget);
  const handleClose = event => setAnchor(null);
  const handleSelect = lang => {
    setAnchor(null);
    setSelectedLanguage(lang);
    languageContext.setLanguage(lang);
  }

  return(
    <>
    <Button
      id="language-icon-button"
      onClick={handleOpen}
      className={classes.langSelectButton}
      startIcon={
        <Avatar variant="square" alt="NL" src={(selectedLanguage === "nl")? nl_flag : en_flag} className={classes.icon} />
      }
    >
      {selectedLanguage}
    </Button>

    <Menu
      keepMounted
      id="language-menu"
      anchorEl={anchor}
      open={Boolean(anchor)}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
        <MenuItem onClick={e=>{handleSelect("nl")}}>
          <ListItemAvatar className={classes.menuListItem}>
            <Avatar alt="NL" src={nl_flag} variant="square" className={classes.menuListIcon} />
          </ListItemAvatar>
          <Typography variant="inherit">Nederlands</Typography>
        </MenuItem>
        <MenuItem onClick={e=>{handleSelect("en")}}>
          <ListItemAvatar className={classes.menuListItem}>
            <Avatar alt="NL" src={en_flag} variant="square" className={classes.menuListIcon} />
          </ListItemAvatar>
          <Typography variant="inherit">English</Typography>
        </MenuItem>
    </Menu>


    </>
  );
}
export default LanguageSelectorButton;
