/*

  Copyright AySay Broadcast b.v.

*/

import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// import loader
import Loader from './views/Loader';

// import providers/context
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import nlLocale from "date-fns/locale/nl";
import format from "date-fns/format";
import { LanguageProvider } from './providers/Language';
import { UserProvider, UserContext } from './providers/User';
import { KijkMeeThemeProvider } from './providers/Theme';
// import ReportConsoleOutput from './providers/ReportConsoleOutput';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline'

// import fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// polyfill for fetch
import 'whatwg-fetch';

// import components
import Header from './views/Header';
import Footer from './views/Footer';
import Home from './views/Home';
import CookieWarning from './views/CookieWarning';

// lazy loading..
const ViewEvent = React.lazy(()=>import('./views/ViewEventV2'));
const ManageEvent = React.lazy(()=>import('./views/dashboard/ManageEvent'));
const ViewEmbeddedEvent = React.lazy(()=>import('./views/embed/ViewEmbeddedEvent'));
const ViewEmbeddedChat = React.lazy(()=>import('./views/embed/ViewEmbeddedChat'));
const ViewEventModerator = React.lazy(()=>import('./views/ViewEventModerator'));
const Info = React.lazy(()=>import('./views/Info'));
const Events = React.lazy(()=>import('./views/Events'));
const Servers = React.lazy(()=>import('./views/Servers'));
const NewEvent = React.lazy(()=>import('./views/NewEvent'));
const Users = React.lazy(()=>import('./views/Users'));
const NewUser = React.lazy(()=>import('./views/NewUser'));
const EditUser = React.lazy(()=>import('./views/EditUser'));
const UserUsage = React.lazy(()=>import('./views/users/UserUsage'));
const UserSignUp = React.lazy(()=>import('./views/users/UserSignUp'));
const UserVerifySignUp = React.lazy(()=>import('./views/users/UserVerifySignUp'));
const Payments = React.lazy(()=>import('./views/payments/Payments'));

// localization
class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "HH:mm - dd-MM-yyyy", { locale: this.locale });
  }
}

const useStyles = makeStyles((theme) => ({
  embedded: {
    backgroundColor: '#343a40',
  },
  webapp: {
    background: '#FEFEFE',
  },
}));

// login needed for route
const PrivateRoute = ( {component: Component, ...rest } ) => {
  const { token } = React.useContext( UserContext );
  if( token ) {
    return(
      <Route
        {...rest}
        render={ props => (<Component {...props} />) }
      />
    );
  }
  else {
    return(
      <Redirect to={ {pathname: "/"} } />
    );
  }
}


// report console messages
// ReportConsoleOutput();


const App = () => {
  const classes = useStyles();
  return (
    <Router>
      <KijkMeeThemeProvider>
      <CssBaseline />
      <LanguageProvider>
      <UserProvider>
      <MuiPickersUtilsProvider utils={LocalizedUtils} locale={nlLocale}>

      <React.Suspense fallback={<Loader />}>

        <CookieWarning />

        <Switch>

          <Route exact path="/embed-chat/:event_id/:stream_id">
            <ViewEmbeddedChat />
          </Route>
          <Route exact path="/embed/:event_guid">
            <ViewEmbeddedEvent />
          </Route>

          <Route path="*" className={classes.webapp}>
            <Header />
            <Switch>
              <Route exact path="/">
                <Home />
                <div style={{height: 75}}></div>
                <Footer />
              </Route>
              <Route exact path="/info">
                <Info />
                <div style={{height: 150}}></div>
                <Footer />
              </Route>
              <Route exact path="/sign-up">
                <UserSignUp />
                <Footer />
              </Route>
              <Route exact path="/verify-sign-up">
                <UserVerifySignUp />
                <Footer />
              </Route>
              <PrivateRoute exact path="/dashboard">
                <Events />
                <div style={{height: 150}}></div>
                <Footer />
              </PrivateRoute>

              <PrivateRoute exact path="/manage/events">
                <Events />
                <div style={{height: 150}}></div>
                <Footer />
              </PrivateRoute>
              <PrivateRoute exact path="/manage/events/new">
                <NewEvent />
                <div style={{height: 150}}></div>
                <Footer />
              </PrivateRoute>
              {/*
              <PrivateRoute exact path="/manage/events/buy">
                <BuyEvent />
                <div style={{height: 150}}></div>
                <Footer />
              </PrivateRoute>
              */}
              <PrivateRoute exact path="/manage/events/:event_id">
                <ManageEvent />
                <div style={{height: 150}}></div>
                <Footer />
              </PrivateRoute>
              <PrivateRoute exact path="/dashboard/users">
                <Users />
                <div style={{height: 150}}></div>
                <Footer />
              </PrivateRoute>
              <PrivateRoute exact path="/dashboard/users/new">
                <NewUser />
                <div style={{height: 150}}></div>
                <Footer />
              </PrivateRoute>
              {/*
              <PrivateRoute exact path="/dashboard/users/register">
                <UserRegister />
                <div style={{height: 150}}></div>
                <Footer />
              </PrivateRoute>
              */}
              <PrivateRoute exact path="/dashboard/users/:user_id">
                <EditUser />
                <div style={{height: 150}}></div>
                <Footer />
              </PrivateRoute>
              <PrivateRoute exact path="/dashboard/users/:user_id/usage">
                <UserUsage />
                <div style={{height: 150}}></div>
                <Footer />
              </PrivateRoute>
              <PrivateRoute exact path="/dashboard/servers">
                <Servers />
                <div style={{height: 150}}></div>
                <Footer />
              </PrivateRoute>
              <PrivateRoute exact path="/dashboard/payments">
                <Payments />
                <div style={{height: 150}}></div>
                <Footer />
              </PrivateRoute>
              <Route exact path={["/:event_guid/chat", "/:event_guid/moderator", "/:event_guid/moderate"]}>
                <ViewEventModerator />
                <div style={{height: 150}}></div>
                <Footer />
              </Route>
              <Route path="/:event_guid">
                <ViewEvent />
              </Route>
            </Switch>
          </Route>

        </Switch>
      </React.Suspense>
      </MuiPickersUtilsProvider>
      </UserProvider>
      </LanguageProvider>
      </KijkMeeThemeProvider>

    </Router>
  );
}

export default App;
