/*

  Copyright AySay Broadcast b.v.

*/

import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import pv from '../static/privacy-verklaring.pdf';
import av from '../static/algemene-voorwaarden.pdf';

// import
import logo from '../static/header_logo.png';
import { LanguageContext } from '../providers/Language';

// styles
const useStyles = makeStyles((theme) => ({
  footerContainer: {
    width: '100%',
    maxWidth: 4000,
    background: theme.overrides.MuiAppBar.colorPrimary.backgroundColor,
    color: theme.overrides.MuiAppBar.colorPrimary.color,
    boxShadow: '-1px -1px 5px ' + theme.palette.grey[700],
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  footerContainer2: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    maxWidth: 4000,
    background: theme.overrides.MuiAppBar.colorPrimary.backgroundColor,
    color: theme.overrides.MuiAppBar.colorPrimary.color,
    boxShadow: '-1px -1px 5px ' + theme.palette.grey[700],
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    borderTop: '1px solid ' + theme.overrides.MuiAppBar.colorPrimary.backgroundColor,
    borderBottom: '1px solid ' + theme.overrides.MuiAppBar.colorPrimary.backgroundColor,
  },
  logo: {
    marginTop: 2,
    maxWidth: 180,
    maxHeight: 20,
  },
  copyrightText: {
    color: theme.overrides.MuiAppBar.colorPrimary.color,
    fontSize: "0.6em",
    verticalAlign: 'middle'
  },
  privacyText: {
    fontSize: "0.6em",
    verticalAlign: 'middle',
    marginRight: theme.spacing(2),
  },
}));

const Footer = ( props ) => {

  const classes = useStyles();
  const { text } = React.useContext( LanguageContext );
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return(
    <>
      <Container disableGutters className={(matches)?classes.footerContainer:classes.footerContainer2}>
        <Grid container>
          <Grid item xs container alignItems="center">
            <img src={logo} className={classes.logo} alt="logo" />
          </Grid>
          <Grid item xs style={{flexGrow:2}}></Grid>
          <Grid item xs container justifyContent="flex-end" alignItems="center">
            <Typography variant="caption" className={classes.privacyText}>
              <Link target="_blank" href={pv}>Privacy Verklaring</Link>
            </Typography>
            <Typography variant="caption" className={classes.privacyText} color="secondary">
              <Link target="_blank" href={av}>Algemene Voorwaarden</Link>
            </Typography>
            <Typography variant="caption" className={classes.copyrightText}>
              © 2019-{new Date().getFullYear()} {text.footer.copyright}.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Footer;
