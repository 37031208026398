
/*

  Copyright AySay Broadcast b.v.

*/


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '../providers/Language';
import { UserContext, decodeTokenPayload } from '../providers/User';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  inputField: {
    marginTop: 10,
    marginBottom: 10,
  },
  container: {
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    textAlign: 'center',
  },
  loader: {
    position: 'absolute',
    top: '50px',
    left: '44%',
  },
  forgotPassword: {
    marginLeft: 20,
    flexGrow: 1,
  },
  buttons: {
    margin: 10,
  },
}));

const Login = ( props ) => {

  const { text } = React.useContext(LanguageContext);
  const user = React.useContext(UserContext);
  const theme = useTheme();
  let history = useHistory();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [ password, setPassword ] = React.useState("");
  const [ email, setEmail ] = React.useState("");
  const [ error, setError ] = React.useState( false );
  const [ resetError, setResetError ] = React.useState( false );
  const [ view, setView ] = React.useState("login");

  const classes = useStyles();

  const { open, onClose } = props;

  const forgotPassword = React.useCallback(() => {
    setResetError( false );
    user.resetPassword(email)
    .then( success => {
      // display error
      if( success ) {
        setView('login');
        return;
      }
      setResetError( true );
    })
    .catch( err => {
      // display error
      setResetError( true );
      console.error( "Login/forgotPassword error:", err );
    })
  }, [user, email]);

  const login = () => {
    setError( false );
    user.login( email, password )
    .then( tokenString => {
      if( tokenString ){
        // close modal
        onClose && onClose();

        // redirect user
        let payload = decodeTokenPayload( tokenString );
        if( payload.role === "Administrator" || payload.role === "Customer" ) {
          history.push('/manage/events');
        }
      }
      else {
        setError(true);
      }
    })
    .catch( err => {
      console.error("Not logged in", err );
      setError( true );
    });
  }
  const enterPressed = ( event ) => {
    if( event.keyCode === 13) {
      login();
    }
  }
  const enterPressed1 = ( event ) => {
    if( event.keyCode === 13) {
      forgotPassword(event.target.value || "");
    }
  }

  if( view === "login") {
    return(
      <>
        <Dialog open={open} fullScreen={fullScreen} fullWidth maxWidth="sm" onClose={onClose} aria-labelledby="login">
          <DialogTitle id="form-dialog-title">{text.login.loginButton}</DialogTitle>

          <DialogContent>

            { user.loading &&
              <div className={classes.container}>
                <CircularProgress className={classes.loader} />
              </div>
            }

            <br /><br />

            <DialogContentText>{text.login.subHeader}</DialogContentText>

            <TextField id="username" disabled={user.loading}
              onKeyDown={enterPressed}
              onChange={e=>{setEmail(e.target.value)}}
              autoFocus
              error={error}
              label={text.login.email} variant="outlined" fullWidth
              className={classes.inputField} required />

            <TextField id="password" disabled={user.loading}
              onKeyDown={enterPressed}
              onChange={e=>{setPassword(e.target.value)}}
              error={error}
              type="password" label={text.login.password} variant="outlined"
              fullWidth className={classes.inputField} required />

            <br /><br /><br />

          </DialogContent>
          <DialogActions>

            <Link href="#" onClick={e=>setView("forgotpassword")} className={classes.forgotPassword}>
              <Typography variant="body2">{text.login.forgotPassword}</Typography>
            </Link>

            <Button onClick={onClose} variant="contained" className={classes.buttons}>
              {text.login.cancelButton}
            </Button>

            <Button onClick={login} color="primary" variant="contained" className={classes.buttons}>
              {text.login.loginButton}
            </Button>

          </DialogActions>
        </Dialog>
      </>
    );
  }
  else {
    return(
      <>
        <Dialog open={open} fullScreen={fullScreen} onClose={onClose} fullWidth maxWidth="sm" aria-labelledby="login">
          <DialogTitle id="form-dialog-title">{text.login.resetPassword}</DialogTitle>

          <DialogContent>

            { user.loading &&
              <div className={classes.container}>
                <CircularProgress className={classes.loader} />
              </div>
            }

            <DialogContentText>{text.login.resetYourPassword}</DialogContentText>

            <TextField id="username" disabled={user.loading}
              onKeyDown={enterPressed1}
              onChange={e=>{setEmail(e.target.value)}}
              autoFocus
              error={resetError}
              helperText={resetError ? "Error: e-mailaddress of gebruiker bestaat niet, er is iets anders gegaan" : undefined}
              label={text.login.email} variant="outlined" fullWidth
              className={classes.inputField} required
            />

          </DialogContent>
          <DialogActions>

            <Button onClick={e=>setView("login")} variant="contained" className={classes.buttons}>
              {text.login.cancelButton}
            </Button>

            <Button onClick={forgotPassword} color="primary" variant="contained" className={classes.buttons}>
              {text.login.resetYourPassword}
            </Button>

          </DialogActions>
        </Dialog>
      </>
    );
  }
}
export default Login;
