
/*

  Copyright AySay Broadcast b.v.

*/


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '../providers/Language';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useLocation } from 'react-router-dom'
import useContact from '../providers/useContact';

const useStyles = makeStyles((theme) => ({
  inputField: {
    marginTop: theme.spacing(2),
  },
  container: {
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    textAlign: 'center',
  },
  loader: {
    position: 'absolute',
    top: '50px',
    left: '44%',
  },
  buttons: {
    margin: 10,
  },
}));

const ContactUs = ( props ) => {

  const { text } = React.useContext( LanguageContext );
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const location = useLocation();
  const [ name, setName ] = React.useState("");
  const [ email, setEmail ] = React.useState("");
  const [ company, setCompany ] = React.useState("");
  const [ question, setQuestion ] = React.useState("");
  const [ valid, setValid ] = React.useState( true );

  const { loading, sendMail } = useContact();

  const { open, onClose } = props;

  const handleSend = () => {

    if( !name || !email || !question ) {
      console.error("handleSend(): missing props !");
      setValid( false );
      return;
    }

    let contact = {
      name: name,
      email: email,
      msg: question,
      company: company,
      browser: navigator.userAgent,
      page: location.pathname,
    }

    sendMail( contact )
    .then( data => {
      // console.log("sendmail success: ", data);
      setValid(true);
      setQuestion("");
      setTimeout( onClose, 800 );
    })
    .catch( err => {
      console.error("sendmail error: ", err );
      setValid(false)
    });
  }

  return(
    <>
      <Dialog open={open} fullScreen={fullScreen} onClose={onClose} aria-labelledby="login">

        <DialogTitle id="form-dialog-title">{text.contactUs.header}</DialogTitle>

        <DialogContent>

          <Typography>{text.contactUs.subTitle}</Typography>
          <Typography variant="caption">{text.contactUs.warningTitle}</Typography>

          <TextField
            className={classes.inputField}
            label={text.contactUs.name}
            fullWidth
            variant="outlined"
            value={name}
            onChange={e=>setName(e.target.value)}
            required
            error={!valid}
          />

          <TextField
            className={classes.inputField}
            label={text.contactUs.email}
            fullWidth
            variant="outlined"
            value={email}
            onChange={e=>setEmail(e.target.value)}
            required
            error={!valid}
          />

          <TextField
            className={classes.inputField}
            label={text.contactUs.company}
            fullWidth
            variant="outlined"
            value={company}
            onChange={e=>setCompany(e.target.value)}
          />

          <TextField
            label={text.contactUs.msg}
            className={classes.inputField}
            fullWidth
            required
            variant="outlined"
            multiline
            minRows={8}
            maxRows={8}
            value={question}
            onChange={e=>setQuestion(e.target.value)}
            error={!valid}
          />

        </DialogContent>
        <DialogActions>

          <Button onClick={e=>{setValid(true);onClose();}} variant="contained" className={classes.buttons}>
            {text.contactUs.cancelButton}
          </Button>

          <Button onClick={handleSend} disabled={loading} color="primary" variant="contained" className={classes.buttons}>
            {text.contactUs.sendButton}
          </Button>

        </DialogActions>
      </Dialog>
    </>
  );
}
export default ContactUs;
