const Settings = {

  header: {
    loginButton: "Login",
    logoutButton: "Logout",
    contactUsButton: "Contact",
    infoButton: "Informatie",
    closeMenuButton: "Sluiten",
    homeButton: "Home",
    dashboard: "Streams",
    users: "Gebruikers",
    servers: "Servers",
  },
  home: {
    header: "Livestream oplossingen voor uw bijeenkomsten",
    subHeader: "Kijk-Mee.nl faciliteert particulieren en bedrijven met livestream techniek om hun bijeenkomsten naar hun kijkers te brengen.",
    contact: "Neem contact op voor meer informatie",
    moreInfo: "Meer Informatie",
    block1: {
      header: "Eigen Adres",
      text: "Bepaal zelf op welk kijk-mee.nl adres je pagina te bekijken is.",
    },
    block2: {
      header: "Beveiliging",
      text: "De stream is volledig te beveiligen, Dit kan door middel van een wachtwoord of een aanmelding van uw kijkers.",
    },
    block3: {
      header: "Onze Servers",
      text: "Ons serverpark is veilig en volledig in eigen beheer. Hierdoor bieden wij veiligheid, stabiliteit en kunnen wij altijd snel reageren.",
    },
    block4: {
      header: "Interactie",
      text: "Door gebruik te maken van interactie kunt u uw livestream naar een hoger niveau brengen. Denk hierbij aan chat, berichten box en poll mogelijkheden.",
    },
    block5: {
      header: "Techniek op Locatie",
      text: "Heeft u extra techniek of hulp nodig bij uw productie, wij hebben verschillende verhuur mogelijkheden.",
    },
    block6: {
      header: "Online Spellen",
      text: "Door een lage latency is kijk-mee geschikt voor online bingo’s, spellen en bijvoorbeeld pubquizen.",
    },
    block7: {
      header: "Persoonlijke Streams",
      text: "Door de hoge betrouwbaarheid is kijk-mee zeer geschikt voor persoonlijke bijeenkomsten zoals uitvaarten, kerkdiensten, bruiloften.",
    },
    block8: {
      header: "Commerciële Streams",
      text: "Uw bedrijf samenbrengen online kan met kijk-mee erg goed. Jaarcijfers of presenteer uw plannen via een livestream. Uw kijk-mee pagina is in uw eigen huisstijl aan te passen.",
    },
  },
  login: {
    subHeader: "Login met uw kijk-mee.nl account",
    loginButton: "Login",
    cancelButton: "Annuleren",
    forgotPassword: "Wachtwoord vergeten?",
    email: "E-mail",
    password: "Wachtwoord",
    resetPassword: "Wachtwoord Resetten",
    resetYourPassword: "Reset uw wachtwoord",
  },
  contactUs: {
    header: "Neem Contact Op",
    sendButton: "Verstuur",
    cancelButton: "Annuleren",
    subTitle: "Stel uw vraag, dan komen wij er zo snel mogelijk op terug.",
    warningTitle: "U neemt via dit contact formulier alleen contact op met kijk-mee.nl, niet met de beheerder van uw stream.",
    name: "Uw Naam",
    email: "Uw Emailadres",
    company: "Bedrijf",
    msg: "Uw Vraag / Opmerking / Bericht",
  },
  event: {
    summary: "Algemene Instellingen",
    status: "Status",
    name: "Naam",
    platform: "Platform",
    url: "Url",
    location: "Plaats",
    contact: "Contact",
    description: "Omschrijving",
    descriptionHelp: "Deze omschrijving komt onder uw video te staan",
    starttime: "Startdatum",
    endtime: "Einddatum",
    date: "Datum",
    comment: "Interne opmerkingen",
    commentHelp: "Interne opmerkingen zijn niet publiek",
    options: "Opties",
    users: "Event Gebruikers",
    usersAdd: "Gebruiker zoeken & toevoegen",
    usersDelete: "Gebruiker uit event verwijderen",
    noUsers: "Geen gebruikers aanwezig",
    noUsersFound: "Geen gebruikers gevonden",
    yourAccessCode: "Uw toegangscode",
    yourAccessCodePlaceholder: "Vul hier uw toegangscode in",
    yourAccessCodeButton: "Toegangscode versturen",
    registerButton: "Registeer",
    registerCodeButton: "Verifier Emailadres",
    newHeader: "Nieuw Evenement Aanmaken",
    newButton: "Evenement Creeren",
    newCancelButton: "Annuleren",
    editHeader: "Wijzig Evenement",
    editButtonShort: "Wijzig",
    editButton: "Evenement Updaten",
    notFound: "Event bestaat niet",
    paymentStatus: "Betalings status",
    paymentStatusUnknown: "Onbekend",
    paymentStatusInvoiced: "Gefactureerd",
    paymentStatusFoute500: "Via Foute500",
    paymentStatusPaid: "Betaald",
    paymentStatusFree: "Niet Factureren",
    createError: "Evenement is niet aangemaakt; naam, url en startdatum zijn verplichte velden",
  },
  events: {
    header: "Stream Evenementen",
    activeOnly: "Actieve Events",
    searchTooltip: "Zoek een event",
    openTooltip: "Open event in nieuwe tab",
    newTooltip: "Creer nieuw event",
    editTooltip: "Beheer Event",
    deleteTooltip: "Verwijder Event",
    newButton: "Nieuw",
    noEvents: "Geen Evenementen gevonden",
    confirmDelete: "Dit verwijdert het volledige evenement, incl. chats, gastenboek etc.. Wilt u de chats of gastenboek eerst downloaden, druk dan op annuleren.",
  },
  controlEvent: {
    eventTab: "Evenement",
    chatTab: "Chat",
    guestbookTab: "Gastenboek",
    infoHeader: "Evenement",
    aliassesHeader: "URL Aliassen",
    themeHeader: "Thema",
    streamsHeader: "Streams",
    usersHeader: "Gebruikers",
    filesHeader: "Bijlagen",
    securityHeader: "Beveiliging & Toegang",
    pluginsHeader: "Plugins",
    publicChatTab: "Chat Overlay",
  },
  player: {
    loading: "De uitzending wordt geladen",
    offline: "Deze uitzending is momenteel niet live",
    noNeedToReload: "We proberen het zo weer, U hoeft de pagina niet te herladen",
    passwordRequired: "Voer uw toegangscode in",
    registrationRequired: "Voor deze uitzending moet u zich registeren",
  },
  error: {
    unknown: "Een onbekende error heeft zich voorgedaan, herlaadt de pagina",
    error: "Error",
    notFound: "De pagina of live uitzending is niet gevonden",
    notAllowed: "Uw gebruiker heeft geen uitnodiging tot deze uitzending.",
    accessCodeWrong: "Toegangscode is niet geldig.",
    uniqueCodeWrong: "Toegangscode is niet geldig of al in gebruik.",
    uniqueCodeWrongDescription: "Heeft u de code in gebruik op een ander apparaat, sluit de stream op dit apparaat, wacht 1 minuut en probeer opnieuw.",
    loginRequired: "U moet ingelogd en uitgenodigd zijn om deze uitzending te kunnen bekijken.",
  },
  footer: {
    copyright: "kijk-mee.nl - Alle rechten voorbehouden",
    company: "kijk-mee.nl is een initiatief van ",
  },
  search: {
    tooltip: "Zoeken",
  },
  toLiveStream: {
    header: "Direct naar een livestream",
    placeholder: "Vul hier uw link of naam in",
    sendButton: "Open Stream"
  },
  chat: {
    header: "Chat",
    enableChat: "Chat",
    guest: "Gast",
    username: "Uw naam in de chat",
    noMessages: "Er zijn nog geen chatberichten",
    bePatient: "Even wachten met uw volgende bericht",
    messagePlaceholder: "Uw bericht...",
    sendButtonTooltip: "Plaats uw bericht in de chat",
    newMessages: "Nieuwe Chatberichten",
    daysAgo: "dag(en) geleden",
    hiddenUsername: "Anoniem",
  },
  guestbook: {
    header: "Gastenboek",
    enableGuestbook: "Gastenboek",
    name: "Naam",
    email: "E-Mail",
    address: "Adres (incl huisnummer)",
    postcode: "Postcode",
    city: "Stad",
    country: "Land",
    message: "Uw Bericht",
    noMessages: "Het gastenboek heeft nog geen berichten",
    contentHeader: "Geplaatste Berichten",
    inputHeader: "Plaats Uw Bericht",
    sendButton: "Plaats uw bericht",
  },
  stream: {
    streamInfo: "Stream Informatie",
    name: "Naam",
    description: "Omschrijving onder video",
    description2: "Omschrijving links van video",
    type: "Streamtype",
    record: "Stream Opnemen",
    cdnUrl: "CDN Url (Optioneel)",
    iframeUrl: "Iframe Url",
    youtubeVideoId: "Youtube video Id",
    stats: "Statistieken",
    views: "Kijkers Nu",
    maxViews: "Kijkers Piek",
    graph: "Historische Kijkers Grafiek",
    noGraph: "Geen grafiekgegevens beschikbaar",
    starttime: "Uitzending Starttijd",
    endtime: "Uitzending Eindtijd",
    poster: "Poster",
    plugins: "Plugins (worden rechts van video getoond)",
    uploadPosterTooltip: "Upload nieuwe poster",
    deletePosterTooltip: "Delete poster",
    encoderSettings: "Encoder Instellingen",
    url: "Stream Url",
    backupUrl: "Stream Url Backup",
    key: "Stream Key / Naam",
    user: "Gebruiker",
    password: "Wachtwoord",
    newStream: "Nieuwe Stream Toevoegen",
    createStream: "Stream Toevoegen",
    editStream: "Stream Wijzigen",
    typeNormal: "Livestream",
    typeYoutube: "Youtube Embed",
    typeIframe: "Iframe Embed",
    typeNone: "No Video",
    guid: "Stream Url Name",
    newButton: "Stream Toevoegen",
    editButton: "Wijzig",
    deleteButton: "Delete",
    confirmDelete: "Stream verwijderen?",
    doesNotExist: "Geen Stream / Stream bestaat niet",
    deleteStreamConfirmMsg: "Dit kan niet ongedaan worden, u verliest tevens de stream statistieken & chats",
    deleteLogoConfirmMsg: "Logo verwijderen?",
    selectAttachment: "Selecteer Bijlage of Opname",
    noAttachments: "Geen bijlage beschikbaar",
  },
  toClipboard: {
    tooltip: "Kopieer tekst naar klembord",
    successMessage: "Tekst gekopieerd",
  },
  files: {
    header: "Bijlagen",
    settings: "Upload Instellingen",
    new: "Upload Bijlage",
    deleteTooltip: "Verwijder Bijlage / Bestand",
    deleteConfirm: "Bestand Verwijderen?",
    noAttachments: "Geen bijlagen aanwezig",
    allowedFiletypes: "Toegestane bestanden: .jpg .jpeg .gif .png .odt .odf .docx .ppt .mp4 .pdf .csv",
    loading: "Bestanden laden...",
  },
  aliasses: {
    header: "URL Aliassen",
    new: "Nieuwe Alias",
    newLabel: "Alias",
    newHelpText: "Voeg een URL alias toe, om bijvoorbeeld typefouten te voorkomen",
    addTooltip: "Voeg alias toe aan het evenement",
    deleteTooltip: "Verwijder Alias",
    noAliasses: "Dit evenement heeft geen URL aliassen",
  },
  security: {
    header: "Beveiliging & Toegang",
    selectSecurityLabel: "Kies beveiliging voor uw stream",
    selectNone: "Uw pagina is openbaar.",
    selectRegister: "Uw pagina is openbaar, maar kijkers moeten zich registeren met hun emailadres.",
    selectAccessCodes: "Uw pagina is niet openbaar, beveiligd met een of meer toegangscodes.",
    selectUniqueCodes: "Uw pagina is niet openbaar, elke kijker heeft een unieke toegangscode nodig.",
    selectUserLogin: "Beveilig uw pagina met uitnodigingen voor gebruikers.",
  },
  accesscodes: {
    header: "Toegangscodes",
    new: "Nieuwe toegangscode toevoegen",
    newLabel: "Toegangscode",
    newHelpText: "Vul een toegangscode in voor uw evenement, laat het veld leeg als het evenement voor iedereen toegankelijk moet zijn",
    deleteTooltip: "Verwijder toegangscode",
    addTooltip: "Toegangscode toevoegen",
    noProtection: "Geen toegangscodes aangemaakt, uitzending is voor iedereen toegankelijk",
  },
  uniquecodes: {
    header: "Unieke toegangscode per kijker",
    addCodesButton: "Codes Toevoegen",
    importCodesButton: "Codes Toevoegen & Versturen",
    generateCodesButton: "Genereer +10 codes",
    clearCodesButton: "Verwijder alle codes",
    downloadCodesButton: "Download alle Codes",
    addNewCodeTextFieldLabel: "Nieuwe code toevoegen",
    addNewEmailTextFieldLabel: "Nieuw emailadres met code toevoegen",
    loading: "Loading...",
    noCodes: "Er zijn nog geen codes toegevoegd aan uw event, dit maakt uw event ontoegankelijk.",
  },
  users: {
    header: "Gebruikers",
    newHeader: "Nieuwe Gebruiker",
    newButton: "Gebruiker Toevoegen",
    newTooltip: "Voeg een nieuwe gebruiker toe",
    newCancelButton: "Annuleren",
    name: "Naam",
    email: "Email",
    phone: "Telefoon",
    company: "Bedrijf",
    language: "Taal",
    locale: "Landinstelling",
    role: "Rol",
    explainRoles: "Een Administrator heeft geen beperkingen. Een Customer kan events creeren, krijg alleen zijn eigen events te zien. Moderator kan bij de modaratepagina van een event. Een User mag de site gebruiken",
    options: "Opties",
    noUsersFound: "Geen gebruikers gevonden",
    editHeader: "Gebruiker aanpassen",
    editButton: "Gebruiker Wijzigen",
    editTooltip: "Wijzig deze gebruiker",
    deleteTooltip: "Verwijder deze gebruiker",
    manageUsersButton: "Beheer Gebruikers",
    manageUsersCloseButton: "Sluit Gebruikerbeheer",
    myAccount: "Mijn Account",
    changePassword: "Mijn Wachtwoord Wijzigen",
    currentPassword: "Uw Huidige Wachtwoord",
    newPassword: "Uw Nieuwe Wachtwoord",
    confirmPassword: "Bevestig Uw Nieuwe Wachtwoord",
    passwordMismatch: "Uw nieuwe wachtwoorden komen niet overeen",
    passwordChangeFailed: "Uw wachtwoord is niet veranderd, klopt uw oude wachtwoorden",
    cancelButton: "Annuleren",
    updateButton: "Aanpassen",
  },
  theme:{
    header: "Thema",
    enabled: "Thema Ingeschakeld",
    logo: "Custom Logo",
    appbarBgColor: "Menubar achtergrond kleur",
    appbarTextColor: "Menubar tekst kleur",
    primaryColor: "Primaire kleur",
    secondaryColor: "Secondaire kleur",
    backgroundColor: "Achtergrond kleur",
    applyTheme: "Thema Toepassen",
    resetTheme: "Standaard Thema Herstellen",
  },
  pagination: {
    rowsPerPage: "Per pagina"
  },
  registerViewer: {
    header: "Registreer kijkers",
  },
  publicChat: {
    header: "Chat Overlay"
  },
  foute500: {
    header: "Foute 500 Klantenservice",
  },
  recordings: {
    header: "Opnames",
    streamHeader: "Opname(s) van de stream: ",
    noFiles: "Er zijn geen opnames beschikbaar voor deze stream.",
    noStreams: "Er zijn momenteel geen opnames beschikbaar.",
    deleteRecording: "Weet u zeker dat u de opname wilt verwijderen?",
    autoDeleteWarning: "Alle opnames worden automatisch verwijderd 1 maand na opname.",
  },
  stats: {
    header: "Statistieken",
    pageVisiters: "Uniek aantal bezoekers op event pagina: ",
    streamHeader: "Stream - ",
    currentViewers: "Huidig aantal kijkers: ",
    peakViewers: "Piek aantal gelijktijdige kijkers: ",
    graphHeader: "Live grafiek aantal gelijktijdige kijkers:",
    noData: "geen data",
    downloadVisitors: "Download Paginabezoeken data als CSV",
    downloadViews: "Download Streamviews data als CSV",
  },
  embed: {
    header: "Embed Stream met IFrame",
    tip: "Gebruik onderstaande code om de player op uw eigen pagina te implementeren. Let op: de chat functie word hierin niet meegenomen."
  },
  controlMenu: {
    showMenu: "Toon Event Menu",
    hideMenu: "Verberg Event Menu",
    backToOverview: "Terug naar stream overzicht",
    showStream: "Bekijk Stream",
    showNoStream: "Geen stream weergeven",
    showChat: "Open Chat",
    showNoChat: "Geen chat weergeven",
    summary: "Algemene Instellingen",
    upload: "Encoder Instellingen",
    streams: "Pagina's",
    addStream: "Creer Pagina",
    deleteStream: "Verwijder Pagina",
    streamPage: "Pagina Instellingen",
    security: "Beveiliging & Toegang",
    aliasses: "Url Aliassen",
    users: "Gebruikers",
    theme: "Thema",
    attachments: "Bestandsupload",
    recordings: "Opnames",
    stats: "Statistieken",
    embed: "Embed Stream met iFrame",
    goto: "Open Event Pagina",
    gotoModerator: "Open Event Moderator Pagina",
    moderators: "Moderators",
  },
  slido: {
    header: "Slido"
  },
  iframe: {
    header: "Iframe",
  },
  register: {
    header: "Geregistreerde Kijkers"
  },
  moderator: {
    header: "Moderators",
    subHeader: "Geef een moderator snel toegang tot de chat en livestream statistieken op: ",
    notLoggedIn: "Log in of vul uw pincode in",
    checkPinButton: "Verstuur Pincode",
    notModeraterPinWarning: "Dit is niet uw moderator pincode, maar de toegangscode voor de stream",
    pinCodeHelperText: "Alleen getallen en tussen 4 tot 8 cijfers",
    newPin: "Nieuwe Moderator Pin Toevoegen",
    noPins: "Geen pincodes aanwezig",
  },
  payments: {
    header: "Uw Betalings Overzicht",
    date: "Datum",
    user: "Gebruiker",
    event: "Event",
    amount: "Bedrag",
    status: "Status",
    noPayments: "Uw heeft nog geen betalingen gedaan",
  }
}
export default Settings;
