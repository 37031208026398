const Settings = {

  header: {
    loginButton: "Login",
    logoutButton: "Logout",
    contactUsButton: "Contact Us",
    infoButton: "Information",
    closeMenuButton: "Close",
    homeButton:  "Home",
    dashboard: "Streams",
    users: "Users",
    servers: "Servers",
  },
  home: {
    header: "Livestream solutions for online gatherings",
    subHeader: "Kijk-Mee.nl facilitates livestreams for consumers and business customers.",
    contact: "Contact us for more information",
    moreInfo: "More Information",
    block1: {
      header: "Pick Your Address",
      text: "Pick your own webaddress for your livestream, for example kijk-mee.nl/your-name-here .",
    },
    block2: {
      header: "Security",
      text: "Secure your stream with an accesscode or send an invitation to login by email.",
    },
    block3: {
      header: "Our Servers",
      text: "Our servers are secured, in-house and within The Netherlands.",
    },
    block4: {
      header: "Interaction",
      text: "Interact with your viewers and improve their experience. You can use chatrooms, guestbooks and polls.",
    },
    block5: {
      header: "People and Equipment",
      text: "In need of experienced people or equipment? Contact us for more information.",
    },
    block6: {
      header: "Online Games",
      text: "Our lowlatency streams are very suitable for online games like Bingo or Pubquizes.",
    },
    block7: {
      header: "Personal Streams",
      text: "The high availability of kijk-mee.nl makes us suitable for funerals, weddings and other personal gatherings.",
    },
    block8: {
      header: "Commercial Streams",
      text: "Present your meetings, presentations or online events on a page styled with your own corporate identity.",
    },
  },
  login: {
    subHeader: "Login with your kijk-mee.nl account",
    loginButton: "Login",
    cancelButton: "Cancel",
    forgotPassword: "Forgot your password?",
    email: "E-mail",
    password: "Password",
    resetPassword: "Password Reset",
    resetYourPassword: "Reset your password",
  },
  contactUs: {
    header: "Contact Us",
    sendButton: "Send",
    cancelButton: "Cancel",
    subTitle: "Ask your question and we will try to answer it as fast as possible",
    warningTitle: "You can only contact kijk-mee.nl via this form, this does not contact the provider of your stream directly.",
    name: "Your Name",
    email: "Your E-mailaddress",
    company: "Company",
    msg: "Your Question / Remarks / Message"
  },
  event: {
    summary: "Main Settings",
    status: "Status",
    name: "Name",
    platform: "Platform",
    url: "Url",
    location: "Location",
    contact: "Contact",
    description: "Description",
    descriptionHelp: "Description will appear below the video",
    starttime: "Startdate",
    endtime: "Enddate",
    date: "Date",
    comment: "Internal comments",
    commentHelp: "Internal comments are not public",
    options: "Options",
    users: "Event Users",
    usersAdd: "Find and Add User",
    usersDelete: "Remove user from event",
    noUsers: "No users in event",
    noUsersFound: "No User Found",
    yourAccessCode: "Access Code",
    yourAccessCodePlaceholder: "Enter your accesscode here",
    yourAccessCodeButton: "Send Accesscode",
    registerButton: "Register",
    registerCodeButton: "Verify Emailaddress",
    newHeader: "Create New Event",
    newButton: "Create Event",
    newCancelButton: "Cancel",
    editHeader: "Change Event",
    editButtonShort: "Edit",
    editButton: "Update Event",
    notFound: "Event does not exist",
    paymentStatus: "Payment Status",
    paymentStatusUnknown: "Unknown",
    paymentStatusInvoiced: "Invoice Sent",
    paymentStatusFoute500: "Via Foute500",
    paymentStatusPaid: "Paid",
    paymentStatusFree: "No invoice needed",
    createError: "Event not created; name, url and startdate are required",
  },
  events: {
    header: "Stream Events",
    activeOnly: "Active Streams",
    searchTooltip: "Find an Event",
    newTooltip: "Create new Event",
    openTooltip: "Open event in new tab",
    editTooltip: "Manage Event",
    deleteTooltip: "Delete Event",
    newButton: "New",
    noEvents: "No Events Found",
    confirmDelete: "Dit verwijdert het volledige evenement, incl. chats, gastenboek etc.. Wilt u de chats of gastenboek eerst downloaden, druk dan op annuleren.",
  },
  controlEvent: {
    eventTab: "Evenement",
    chatTab: "Chat",
    guestbookTab: "Gastenboek",
    infoHeader: "Information",
    aliassesHeader: "URL Aliasses",
    themeHeader: "Thema",
    streamsHeader: "Livestreams",
    usersHeader: "Users",
    filesHeader: "Attachments",
    securityHeader: "Security & Access",
    pluginsHeader: "Plugins",
    publicChatTab: "Chat Overlay",
  },
  player: {
    loading: "Loading the broadcast",
    offline: "The Broadcast is currently not live",
    noNeedToReload: "No need to reload the webpage",
    passwordRequired: "This stream requires an accesscode",
    registrationRequired: "You need to register for this stream",
  },
  error: {
    unknown: "An unknown error has occured, please try to reload the page",
    error: "Error",
    notFound: "The page or livestream you requested, cannot be found",
    notAllowed: "Your user is not allowed to view this broadcast",
    accessCodeWrong: "The accesscode is not correct",
    uniqueCodeWrong: "The accesscode is not correct or already in use",
    uniqueCodeWrongDescription: "Are you using the code on another device, close the webpage on the other device, wait 1 minute and try again.",
    loginRequired: "You need to login and have an invite to view this broadcast",
  },
  footer: {
    copyright: "kijk-mee.nl - All rights reserved",
    company: "kijk-mee.nl is a company by ",
  },
  search: {
    tooltip: "Search",
  },
  toLiveStream: {
    header: "Go directly to your livestream",
    placeholder: "Enter or Paste your link here",
    sendButton: "Open Stream"
  },
  chat: {
    header: "Chat",
    enableChat: "Chat",
    guest: "Guest",
    username: "Your Chat Name",
    noMessages: "No chatmessages yet",
    bePatient: "Please wait...",
    messagePlaceholder: "Your message...",
    sendButtonTooltip: "Add message to chat",
    newMessages: "New Chatmessages",
    daysAgo: "days ago",
    hiddenUsername: "Anonymous",
  },
  guestbook: {
    header: "Guestbook",
    enableGuestbook: "Guestbook",
    name: "Name",
    email: "Email",
    address: "Address",
    postcode: "PostalCode",
    city: "City",
    country: "Country",
    message: "Your Message",
    noMessages: "There are no messages in the guestbook",
    contentHeader: "Posted Messages",
    inputHeader: "Add Your Reaction",
    sendButton: "Submit your entry",
  },
  stream: {
    streamInfo: "Stream Information",
    name: "Name",
    description: "Description below video",
    description2: "Description left of video",
    type: "Stream type",
    record: "Record Stream",
    cdnUrl: "CDN Url (Optional)",
    iframeUrl: "Iframe Url",
    youtubeVideoId: "Youtube video Id",
    stats: "Statistics",
    views: "Viewers Now",
    maxViews: "Viewers Max",
    graph: "Historical Viewergraph",
    noGraph: "No graphdata available",
    starttime: "Starttijd",
    endtime: "Eindtijd",
    poster: "Poster",
    plugins: "Plugins (shown right of video)",
    uploadPosterTooltip: "Upload new poster",
    deletePosterTooltip: "Delete poster",
    encoderSettings: "Encoder Settings",
    url: "Stream Url",
    key: "Stream Key",
    backupUrl: "Stream Url Backup",
    user: "User",
    password: "Password",
    newStream: "Nieuwe Stream Toevoegen",
    createStream: "Add Stream",
    editStream: "Edit Stream",
    typeNormal: "Livestream",
    typeYoutube: "Youtube Embed",
    typeIframe: "Iframe Embed",
    typeNone: "No Video",
    guid: "Stream Url Name",
    newButton: "Add Stream",
    editButton: "Edit",
    deleteButton: "Delete",
    confirmDelete: "Delete Stream?",
    doesNotExist: "No Stream / Stream does not exist",
    deleteStreamConfirmMsg: "This cannot be undone and will delete stats and chats of the stream",
    deleteLogoConfirmMsg: "Delete logo?",
    selectAttachment: "Select recording or attachment",
    noAttachments: "No attachment available",
  },
  toClipboard: {
    tooltip: "Copy text to clipboard",
    successMessage: "Text copied",
  },
  files: {
    header: "Attachments",
    settings: "Attachment Settings",
    new: "Upload Attachments",
    deleteTooltip: "Verwijder Bijlage / Bestand",
    deleteConfirm: "Delete File?",
    noAttachments: "No attachments present",
    allowedFiletypes: "Allowed filetypes for upload: .jpg .jpeg .gif .png .odt .odf .docx .ppt .mp4 .pdf .csv",
    loading: "Loading files...",
  },
  aliasses: {
    header: "URL Aliasses",
    new: "New Alias",
    newLabel: "Alias",
    newHelpText: "Enter url aliasses, to help with typo's etc.",
    addTooltip: "Add alias to Event",
    deleteTooltip: "Remove Alias",
    noAliasses: "You have not added any URL aliasses",
  },
  security: {
    header: "Security & Access",
    selectSecurityLabel: "Pick your broadcast security",
    selectNone: "Your event is public",
    selectRegister: "Your event is public, but viewers must register with a valid email",
    selectAccessCodes: "Your event is not public, viewers need an accesscode",
    selectUniqueCodes: "Your event is not public, each viewer needs an unique accesscode",
    selectUserLogin: "Secure with user invites",
  },
  accesscodes: {
    header: "Accesscodes",
    new: "New Accesscode",
    newLabel: "Accesscode",
    newHelpText: "Please enter a accesscode to secure your event, leave blank to allow everybody",
    deleteTooltip: "Remove Accesscode",
    addTooltip: "Add Accesscode",
    noProtection: "The event is public accessible",
  },
  uniquecodes: {
    header: "Unique accesscode per viewer",
    addCodesButton: "Add Codes",
    importCodesButton: "Add & Email Codes",
    generateCodesButton: "Generate +10 codes",
    clearCodesButton: "Remove all codes",
    downloadCodesButton: "Download Codes",
    addNewCodeTextFieldLabel: "Add a new code",
    addNewEmailTextFieldLabel: "Add a new emailaddress with code",
    loading: "Laden...",
    noCodes: "No codes have been added to your event, your event is not accessible to anyone",
  },
  users: {
    header: "Users",
    newHeader: "New User",
    newButton: "Add User",
    newTooltip: "Add a new user",
    newCancelButton: "Cancel",
    name: "Name",
    email: "Email",
    phone: "Phone",
    company: "Company",
    language: "Language",
    locale: "CountrySetting",
    role: "Role",
    explainRoles: "An Admin has no restrictions, A Customer can create his own events. A Moderator can moderate a chat and see current viewerstats. A User can login and use the site.",
    options: "Options",
    noUsersFound: "No users found",
    editHeader: "Edit User",
    editButton: "Save User",
    editTooltip: "Edit this user",
    deleteTooltip: "Delete this user",
    manageUsersButton: "Manage Users",
    manageUsersCloseButton: "Close Usermanager",
    myAccount: "My Account",
    changePassword: "Change My Password",
    currentPassword: "Your Current Password",
    newPassword: "Your New Password",
    confirmPassword: "Confirm Your New Password",
    passwordMismatch: "Your new passwords are not the same",
    passwordChangeFailed: "Your password has not been changed! Is your old password correct?",
    cancelButton: "Cancel",
    updateButton: "Update",
  },
  theme:{
    header: "Theme",
    enabled: "Theme Enabled",
    logo: "Custom Logo",
    appbarBgColor: "Menubar background color",
    appbarTextColor: "Menubar text color",
    primaryColor: "Primary color",
    secondaryColor: "Secondary color",
    backgroundColor: "Background color",
    applyTheme: "Apply Theme",
    resetTheme: "Reset Theme",
  },
  pagination: {
    rowsPerPage: "Per Page"
  },
  registerViewer: {
    header: "Register Viewers",
  },
  publicChat: {
    header: "Chat Overlay"
  },
  foute500: {
    header: "Foute 500 Klantenservice",
  },
  recordings: {
    header: "Recordings",
    streamHeader: "Recordings of stream: ",
    noFiles: "There are no recordings available for this stream.",
    noStreams: "There are no recordings available.",
    deleteRecording: "Are you sure, you want to delete this recording?",
    autoDeleteWarning: "All recordings wil be automaticly deleted 1 month after recording.",
  },
  stats: {
    header: "Stats",
    pageVisiters: "Number of pagevisits: ",
    streamHeader: "Stream - ",
    currentViewers: "Current number of viewers: ",
    peakViewers: "Peak number of viewers: ",
    graphHeader: "Live viewer graph:",
    noData: "no data",
    downloadVisitors: "Download Visitor Statistics as CSV",
    downloadViews: "Download View Statistics as CSV",
  },
  embed: {
    header: "Embed Stream With IFrame",
    tip: "Use the code below the embed the player on your own website, please keep in mind that the chat wil not be visible in the embedded stream."
  },
  controlMenu: {
    showMenu: "Show Event Menu",
    hideMenu: "Hide Event Menu",
    backToOverview: "Back to stream overview",
    showStream: "Show Stream",
    showNoStream: "Do not display stream",
    showChat: "Open Chat",
    showNoChat: "Do not display chat",
    summary: "Main Settings",
    upload: "Encoder Settings",
    streams: "Pages",
    addStream: "Create Stream",
    deleteStream: "Delete Stream",
    streamPage: "Page",
    security: "Security & Access",
    aliasses: "Url Aliasses",
    accesscodes: "Accesscodes",
    users: "Users",
    theme: "Theme",
    attachments: "Attachments",
    recordings: "Recordings",
    stats: "Statistics",
    embed: "Embed Stream with iFrame",
    goto: "Open Event Page",
    gotoModerator: "Open Event Moderator Page",
    moderators: "Moderators",
  },
  slido: {
    header: "Slido"
  },
  iframe: {
    header: "Iframe",
  },
  register: {
    header: "Geregistreerde Kijkers"
  },
  moderator: {
    header: "Moderators",
    subHeader: "Give a moderator access to the chat and livestream via:",
    notLoggedIn: "Please Login or Enter Pin",
    checkPinButton: "Submit Pincode",
    notModeraterPinWarning: "This is not your Moderator pincode, but the accesscode to the stream",
    pinCodeHelperText: "Numbers only and between 4-8 characters",
    newPin: "Add new moderator pincode",
    noPins: "No Pincode have been added",
  },
  payments: {
    header: "Your Payment Overview",
    date: "Date",
    user: "User",
    event: "Event",
    amount: "Amount",
    status: "Status",
    noPayments: "You have not made any payments",
  }
}
export default Settings;
