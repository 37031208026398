
/*

  AySay Broadcast bv.

*/

import React from 'react';
import Settings from '../config';

const useContact = () => {

  const sendMail = React.useCallback( message => {
    return new Promise( (resolve, reject) => {
      if( !message ) {reject("useContact error: missing props");}
      const url = Settings.api.url + '/contact';
      fetch( url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'omit',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ contact: message }),
      })
      .then( response => {
        if( response.status === 204 || response.status === 200 ) {
          resolve(true);
        }
        else {
          reject( false );
        }
      })
      .catch( err => {
        console.error("useContact error: ", err );
        reject( "useContact error: " + err.toString() );
      });
    });
  },[]);

  return { sendMail };
}
export default useContact;
