
/*

  Copyright AySay Broadcast b.v.

*/


import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

export const Loader = (props) => (
  <Backdrop open={true}>
    <Box align="center">
      <CircularProgress color="primary" />
      {props.msg &&
        <Typography variant="h6">{props.msg}</Typography>
      }
    </Box>
  </Backdrop>
);
export default Loader;
