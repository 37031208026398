/*

  By AySay Broadcast b.v.

*/

import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockIcon from '@material-ui/icons/Lock';
import { LanguageContext } from '../../providers/Language';
import { UserContext } from '../../providers/User';
import { makeStyles } from '@material-ui/core/styles';
import Login from '../Login';
import UserProfile from './UserProfile';
import UserPassword from './UserPassword';


// styles
const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.overrides.MuiAppBar.colorPrimary.backgroundColor,
    color: theme.overrides.MuiAppBar.colorPrimary.color,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    marginLeft: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.light,
  },
}));

export const UserUsage = (props) => {
  const classes = useStyles();
  const { text } = React.useContext(LanguageContext);
  const user = React.useContext(UserContext);

  // states
  const [ menuElAnchor, setMenuElAnchor ] = React.useState(null);
  const [ loginOpen, setLoginOpen ] = React.useState(false);
  const [ profileOpen, setProfileOpen ] = React.useState(false);
  const [ changePasswordOpen, setChangePasswordOpen ] = React.useState(false);

  // handlers
  const openMenu = event => setMenuElAnchor(event.currentTarget);
  const closeMenu = event => setMenuElAnchor(null);
  const openLogin = event => setLoginOpen(true);
  const closeLogin = event => setLoginOpen(false);
  const logout = event => {
    closeMenu();
    user.logout();
  }
  const openProfile = event => setProfileOpen(true);
  const openPassword = event => setChangePasswordOpen(true);

  if( user.token ) {
    return(
      <>
        <Button
          onClick={openMenu}
          className={classes.root}
          startIcon={<AccountBoxIcon className={classes.icon} />}
        >
          {(user.user)?user.user.name : "Account"}
        </Button>

        <Menu
          id="account-menu"
          anchorEl={menuElAnchor}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          open={Boolean(menuElAnchor)}
          onClose={closeMenu}
        >

          <MenuItem disabled>
            <ListItemIcon />
            <ListItemText>
              {(user.user)?user.user.name : "Account"}
            </ListItemText>
          </MenuItem>

          <MenuItem divider />

          <MenuItem onClick={openProfile}>
            <ListItemIcon>
              <PersonIcon/>
            </ListItemIcon>
            <ListItemText>
              {text.users.myAccount}
            </ListItemText>
          </MenuItem>

          <MenuItem onClick={openPassword}>
            <ListItemIcon>
              <LockIcon />
            </ListItemIcon>
            <ListItemText>
              {text.users.changePassword}
            </ListItemText>
          </MenuItem>

          <MenuItem divider />

          <MenuItem onClick={logout}>
            <ListItemIcon>
                <ExitToAppIcon/>
            </ListItemIcon>
            <ListItemText>
              {text.header.logoutButton}
            </ListItemText>
          </MenuItem>

        </Menu>

        { user.user &&
          <UserProfile show={profileOpen} setShow={setProfileOpen} userid={user.user.id} />
        }

        { user.user &&
          <UserPassword show={changePasswordOpen} setShow={setChangePasswordOpen} userid={user.user.id} />
        }

      </>
    );
  }

  return(
    <>
      <Button
        onClick={openLogin}
        className={classes.root}
      >
        {text.header.loginButton}
      </Button>

      <Login open={loginOpen} onClose={closeLogin} />

    </>
  );
}
export default UserUsage;
