/*
Copyright AySay Broadcast b.v.
*/


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '../providers/Language';
import { UserContext } from '../providers/User';
import { useHistory } from "react-router-dom";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import MailIcon from '@material-ui/icons/Mail';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import StorageIcon from '@material-ui/icons/Storage';

import ContactUs from './ContactUs';
import Login from './Login';

const useStyles = makeStyles((theme) => ({
  button: {
    cursor: 'pointer',
    fontSize: '1.1rem',
    marginRight: theme.spacing(2),
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  list: {
    minWidth: 250,
  },
  menuButton: {
    background: theme.overrides.MuiAppBar.colorPrimary.backgroundColor,
    color: theme.overrides.MuiAppBar.colorPrimary.color,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    marginLeft: theme.spacing(1),
  },
}));

const MenuButton = ( props ) => {

  const { text } = React.useContext(LanguageContext);
  const user = React.useContext(UserContext);
  const classes = useStyles();

  let history = useHistory();
  const gotoInfo = () => {
    closeSideMenu();
    history.push("/info");
  }
  const gotoHome = () => {
    closeSideMenu();
    history.push("/");
  }
  const gotoDashboard = () => {
    closeSideMenu();
    history.push("/manage/events");
  }
  const gotoUsers = () => {
    closeSideMenu();
    history.push("/dashboard/users");
  }
  const gotoServers = () => {
    closeSideMenu();
    history.push("/dashboard/servers");
  }
  const logout = () => {
    user.logout();
  }

  const [ sideMenuOpen, setSideMenuOpen ] = React.useState( false );
  const openSideMenu = () => setSideMenuOpen(true);
  const closeSideMenu = () => setSideMenuOpen(false);

  const [ loginOpen, setLoginOpen ] = React.useState(false);
  const [ contactUsOpen, setContactUsOpen ] = React.useState(false);

  const handleLoginClose = () => setLoginOpen(false);
  const handleContactUsClose = () => setContactUsOpen(false);

  return(
    <>

    <Button className={classes.menuButton} onClick={openSideMenu} aria-label="menu">
      <MenuIcon />
    </Button>

    <SwipeableDrawer anchor="right" open={sideMenuOpen} onClose={closeSideMenu} onOpen={openSideMenu}>
      <List className={classes.list}>

        <ListItem button key={"close"} onClick={closeSideMenu}>
          <ListItemIcon><CloseIcon /></ListItemIcon>
          <ListItemText primary={text.header.closeMenuButton} />
        </ListItem>

        <Divider />

        <ListItem button key={"home"} onClick={gotoHome}>
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary={text.header.homeButton} />
        </ListItem>

        { user.token &&
          <>
          <ListItem button key={"streams"} onClick={gotoDashboard}>
            <ListItemIcon><LiveTvIcon /></ListItemIcon>
            <ListItemText primary={text.header.dashboard} />
          </ListItem>

          { user.role === "Administrator" &&
            <>
            <ListItem button key={"users"} onClick={gotoUsers}>
              <ListItemIcon><PeopleAltIcon /></ListItemIcon>
              <ListItemText primary={text.header.users} />
            </ListItem>

            <ListItem button key={"servers"} onClick={gotoServers}>
              <ListItemIcon><StorageIcon /></ListItemIcon>
              <ListItemText primary="Servers" />
            </ListItem>
            </>
          }
          </>
        }

        <ListItem button key={"info"} onClick={gotoInfo}>
          <ListItemIcon><InfoIcon /></ListItemIcon>
          <ListItemText primary={text.header.infoButton} />
        </ListItem>

        <ListItem button key={"contact"} onClick={()=>{closeSideMenu();setContactUsOpen(true);}}>
          <ListItemIcon><MailIcon /></ListItemIcon>
          <ListItemText primary={text.header.contactUsButton} />
        </ListItem>

        <Divider />

        { user.token &&
          <>
          <ListItem button key={"logout"} onClick={logout}>
            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
            <ListItemText primary={text.header.logoutButton} />
          </ListItem>
          </>
        }
        { ! user.token &&
          <ListItem button key={"login"} onClick={()=>{closeSideMenu();setLoginOpen(true);}}>
            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
            <ListItemText primary={text.header.loginButton} />
          </ListItem>
        }

      </List>
    </SwipeableDrawer>

    <ContactUs open={contactUsOpen} onClose={handleContactUsClose} />

    <Login open={loginOpen} onClose={handleLoginClose} />

    </>
  );
}
export default MenuButton;
