/*


    By Aysay B.V.


*/

import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '../../providers/Language';
import useUsers from '../../providers/useUsers';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

//
const useStyles = makeStyles((theme) => ({
  root: {},
  input: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2),
  },
}));

//
export const UserPassword = props => {

  const { show, setShow, userid } = props;

  if( !userid ) {
    throw new Error("UserPassword - missing a userid")
  }

  const { text } = React.useContext(LanguageContext);
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { updatePassword } = useUsers();

  const [ oldPassword, setOldPassword ] = React.useState("");
  const [ newPassword, setNewPassword ] = React.useState("");
  const [ confirmPassword, setConfirmPassword ] = React.useState("");
  const [ error, setError ] = React.useState(false);
  const [ errorMsg, setErrorMsg ] = React.useState("");

  const handleUpdate = React.useCallback( () => {

    if( newPassword !== confirmPassword ) {
      setErrorMsg(text.users.passwordMismatch);
      setError(true);
      return;
    }

    setError(false);

    updatePassword( userid, newPassword, oldPassword )
    .then(success => {
      if( success ) {
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setShow(false);
      } else {
        setError(true);
        setErrorMsg(text.users.passwordChangeFailed);
      }
    })
    .catch(console.error);
  }, [newPassword, oldPassword, confirmPassword, userid, updatePassword, setShow, text.users]);

  return(
    <>
      <Dialog open={show} fullScreen={fullScreen} onClose={e=>setShow(false)}>
          <DialogTitle className={classes.root}>{text.users.myAccount}</DialogTitle>
          <DialogContent>
            <form autoComplete="off">
              <TextField
                label={text.users.currentPassword}
                value={oldPassword}
                onChange={e=>setOldPassword(e.target.value)}
                required
                fullWidth
                className={classes.input}
                variant="outlined"
                type="password"
              />
              <TextField
                label={text.users.newPassword}
                value={newPassword}
                onChange={e=>setNewPassword(e.target.value)}
                required
                fullWidth
                className={classes.input}
                variant="outlined"
                type="password"
              />
              <TextField
                label={text.users.confirmPassword}
                value={confirmPassword}
                onChange={e=>setConfirmPassword(e.target.value)}
                required
                fullWidth
                className={classes.input}
                variant="outlined"
                type="password"
              />
            </form>
            <br />
            { error &&
              <DialogContentText color="error">{errorMsg}</DialogContentText>
            }
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={e=>{setOldPassword("");setNewPassword("");setConfirmPassword("");setShow(false)}} // clear all for safety
              className={classes.button}
            >
              {text.users.cancelButton}
              </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={e=>handleUpdate()}
            >
              {text.users.updateButton}
            </Button>
          </DialogActions>
      </Dialog>
    </>
  );
}
export default UserPassword;
