/*
  Copyright AySay Broadcast bv.
*/

import React from 'react';

import nl from '../languages/nl';
import en from '../languages/en';
const languages = {
  "nl": nl,
  "en": en
}

const inIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

const saveLang = ( lang ) => {
  if( inIframe() ) {
    return;
  }
  try {
    window.localStorage.setItem("kijkmee-lang", lang);
    return true;
  } catch(e) { console.error(e)
    return true;
  }
}
const getLang = () => {
  if( inIframe() ) {
    return "nl"
  }
  try {
    return window.localStorage.getItem("kijkmee-lang") || "nl";
  } catch(e) {
    console.error(e);
    return "nl";
  }
}


const LanguageContext = React.createContext();

const LanguageProvider = ( props ) => {

  const [ text, setText ] = React.useState( languages[getLang() || "nl"]);
  const [ current, setCurrent ] = React.useState( getLang() || "nl" );

  const setLanguage = React.useCallback( ( lang ) => {
    if(!lang || (lang!=="nl" && lang!=="en") ) {
      lang = "nl"
    }
    setText( languages[lang] );
    setCurrent( lang );
    saveLang( lang );
  }, []);

  return(
    <LanguageContext.Provider value={{ text, current, setLanguage }}>
      {props.children}
    </LanguageContext.Provider>
  );
}

export { LanguageContext, LanguageProvider };
