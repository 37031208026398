/*

  By Aysay Broadcast B.V.

*/

import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { UserContext } from '../providers/User';

const tokenname = "kijkmee-cookies";

const getToken = () => {
  try{
    return window.localStorage.getItem( tokenname );
  } catch( err ) {
    return "";
  }
}
const saveToken = (status) => {
  try {
    return window.localStorage.setItem( tokenname, status );
  } catch(err) {
    return false;
  }
}

export const CookieWarning = (props) => {

  const [ open, setOpen ] = React.useState( (getToken()) ? false:true );

  const { enableGA } = React.useContext(UserContext);

  const enableFunctional = () => {
    setOpen(false);
    saveToken("functional");
  }

  const enableAll = () => {
    setOpen(false);
    enableGA();
    saveToken("all");
  }

  React.useEffect(() => {
    const tk = getToken();
    if( tk && tk === "all" ) {
      enableGA();
    }
  }, [enableGA]);

  return(
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        message="Selecteer de cookies die kijk-mee.nl plaatst"
        action={
          <React.Fragment>
            <Button color="primary" variant="contained" size="small" onClick={enableFunctional} style={{marginRight: 8}}>
              Functioneel
            </Button>
            <Button color="primary" variant="contained" size="small" onClick={enableAll}>
              Volledig
            </Button>
          </React.Fragment>
        }
        color="primary"
        />
    </>
  );
}
export default CookieWarning;
