/*
  Copyright AySay Broadcast bv.
*/

import React from 'react';
import Settings from '../config';
import usePageView from './usePageView';
import { useLocation } from 'react-router-dom';

// init context
const UserContext = React.createContext();

const tokenname = "token";

export function validateEmail(email) {
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLowerCase());
}

export function validatePassword(password) {
  // check length
  if( password.length < 10 ) {
    return false;
  }

  // search for at least 1 lowercase letter
  if( password.search(/[a-z]/) < 0 ) {
    return false;
  }

  // search for at least 1 UPPERCASE letter
  if( password.search(/[A-Z]/) < 0 ) {
    return false;
  }

  // search for at least 1 number
  if( password.search(/[0-9]/) < 0 ) {
    return false;
  }

  // search for a single special character
  if( password.search(/[!@#$%^&*()~{}<>?]/) < 0 ) {
    return false;
  }

  return true;
}

export const decodeTokenPayload = ( token ) => {
  let payload = null;
  if( token ) {
    let str = atob(token
      .split(".")[0]
      .replace(/-/g, '+')
      .replace(/_/g, '/'));
    try{
      payload = JSON.parse(str);
    }
    catch(err) {
      console.error(err);
    }
  }
  return payload;
}

const getToken = () => {
  // check for token in localstorage
  let token = undefined;
  try {
    token = window.localStorage.getItem( tokenname );
  }
  catch( err ) {
    console.error( "getToken error", err );
  }
  finally{
    return token;
  }
}
const saveToken = ( token ) => {
  try {
    return window.localStorage.setItem( tokenname, token );
  }
  catch (err) { return undefined;}
}
const deleteToken = (token) => {
  try {
    window.localStorage.removeItem( tokenname )
    return true;
  } catch( err ) {
    console.error(err);
    return false;
  }
};

export const getSession = () => {
  let uc = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8); // eslint-disable-line
    return v.toString(16);
  });
  try {
    const ucl = window.localStorage.getItem("kijkmee-uc");
    if( !ucl ) window.localStorage.setItem("kijkmee-uc", uc);
    else uc = ucl;
    return uc;
  } catch (err) {
    console.error(err);
    return uc;
  }
}

const UserProvider = ( props ) => {

  const [ loading, setLoading ] = React.useState(false);
  const [ user, setUser ] = React.useState(undefined);
  const [ role, setRole ] = React.useState(undefined);
  const [ token, setToken ] = React.useState( getToken() );
  const [ session ] = React.useState( getSession() );
  let { pathname } = useLocation();
  const { registerPageView, gaEnabled, enableGA } = usePageView();

  const resetPassword = React.useCallback( async (email) => {
    if( !email ) {
      return false;
    }
    setLoading( true );
    const url = Settings.api.url + '/users/resetpassword';
    try {
      const options = {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify( {email: email.toString()} )
      }
      let response = await fetch( url, options );
      if( response.status !== 200 ) {
        setLoading( false );
        return false;
      }
      setLoading( false );
      return true;

    } catch ( err ) {
      console.error(err);
      setLoading( false );
      return false;
    }
  }, []);

  const loggedIn = React.useCallback( async () => {
    if(! token ) {
      return;
    }
    setLoading( true );
    try {
      const url = Settings.api.url + '/users/loggedin';
      const options = {
        method: 'HEAD',
        headers: {
          Authorization: (token)?"Bearer "+token : ""
        }
      };
      let response = await fetch( url, options );
      if( response.status !== 204 ) {
        throw new Error("non 204 response: ", response.status);
      }
      // update state we are not waiting for the request
      let payload = decodeTokenPayload( token );

      setLoading( false );
      setUser( payload.user );
      setRole( payload.role );
    }
    catch( err ) {
      setLoading( false );
      setToken( undefined );
      setUser( undefined );

      // window.localStorage.removeItem( tokenname );
      deleteToken( tokenname );
    }
  }, [token]);

  const login = React.useCallback( async ( email, password ) => {
    if( !email || !password ){
      throw new Error("UserProvider: no username or password given");
    }
    //updateState({loading: true, user: undefined, token: undefined, role: undefined})
    setLoading( true );
    setUser( undefined );
    setRole( undefined );
    setToken( undefined );
    let url = Settings.api.url + '/users/login';
    const options = {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify( {email: email, password: password} )
    };
    try {
      let response = await fetch( url, options );
      if( response.status !== 200 ) {
        setLoading( false );
        return;
      }
      let json = await response.json();
      if( ! json.token ) {
        setLoading( false );
        return;
      }

      let payload = decodeTokenPayload( json.token );

      setLoading( false );
      setUser( payload.user );
      setRole( payload.role );
      setToken( json.token );

      saveToken( json.token );

      return json.token;
    }
    catch( err ) {
      setLoading( false );
      return;
    }
  }, []);

  const logout = React.useCallback(async () => {
    try {
      // update state we are not waiting for the request
      setLoading( true );
      setUser( undefined );
      setRole( undefined );
      setToken( undefined );

      deleteToken();

      const url = Settings.api.url + '/users/logout';
      const options = {
        method: 'GET',
        headers: {
          Authorization: (token)?"Bearer "+token : ""
        }
      };
      await fetch( url, options );
      setLoading( false );
    }
    catch( err ) {
      setLoading( false );
      console.error( err );
    }
  }, [token]);

  // check if login is still valid timer
  React.useEffect( () => {
    let timer;
    if( token ){
      loggedIn();
      timer = setInterval(loggedIn, 43200000); // recheck in half a day
    }
    return () => {
      clearInterval(timer);
    }
  }, [loggedIn, token] );

  // register pageviews
  React.useEffect( () => {
    if( pathname && session ) {
      registerPageView( session, pathname );
    }
  }, [session, pathname, registerPageView]);

  return(
    <UserContext.Provider value={{login, logout, loading, user, role, token, loggedIn, resetPassword, session, gaEnabled, enableGA}}>
      {props.children}
    </UserContext.Provider>
  );
}

export { UserContext, UserProvider };
