/*

  AySay Broadcast bv.

*/

import React from 'react';
import { LanguageContext } from '../providers/Language';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container'
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    textAlign: 'center',
  },
  header: {
    marginBottom: 10,
  },
  inputField: {
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    boxShadow: theme.shadows[3],
  },
  sendButton: {
    marginLeft: 1,
    marginRight: 1,
  },
}));

export const ToLiveStream = ( props ) => {

  const classes = useStyles();
  const { text } = React.useContext( LanguageContext );
  const history = useHistory();

  const [ link, setLink ] = React.useState("");

  const { onClose } = props;

  const openStream = () => {
    if( !link || link.length < 3 ) {
      return;
    }
    let url = link;
    url = url.replace("https://", "").replace("http://", "");
    url = url.replace("www.", "");
    url = url.replace(window.location.host, "").replace("/", "");
    history.push("/" + url);
    if( typeof onClose === "function" ) {
      onClose( url );
    }
  };

  const keyPressed = ( event ) => {
    if(event.keyCode === 13) {
      openStream();
    }
  }

  return(
    <Container disableGutters className={classes.root}>
      <FormControl fullWidth className={classes.margin}>
        <TextField
          id="km-link-to"
          className={classes.inputField}
          value={link}
          label={text.toLiveStream.header}
          variant="outlined"
          onChange={e=>{setLink(e.target.value)}}
          onKeyDown={keyPressed}
          placeholder={text.toLiveStream.placeholder}
          InputProps={{
            endAdornment: (
              <Hidden only="xs">
              <InputAdornment position="end">
                <Button variant="contained" color="primary" onClick={openStream}>{text.toLiveStream.sendButton}</Button>
              </InputAdornment>
              </Hidden>
            ),
          }}
        />
        <Hidden smUp>
          <Button variant="contained" color="primary" onClick={openStream} className={classes.sendButton}>{text.toLiveStream.sendButton}</Button>
        </Hidden>
      </FormControl>
    </Container>
  );
}
export default ToLiveStream
