/*

  Copyright AySay Broadcast b.v.

*/

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { Link, useHistory, useRouteMatch } from "react-router-dom";

// import
import LanguageSelectorButton from './LanguageSelectorButton';
import { LanguageContext } from '../providers/Language';
import { UserContext } from '../providers/User';
import { KijkMeeThemeContext } from '../providers/Theme';
import ContactUs from './ContactUs';
import MenuButton from './MenuButton';

import UserAccount from './users/UserAccount';

import kijkmeelogo from '../static/header_logo.png';

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    borderBottom: '1px solid ' + theme.palette.primary.dark,
  },
  viewEventContainer: {
    maxWidth: 1700,
  },
  logo: {
    maxHeight: 50,
    marginLeft: 0,
    marginRight: theme.spacing(2),
    marginTop: 6,
  },
  pageTitle: {
    flexGrow: 1,
    textAlign: 'left',
  },
  info: {},
  language: {},
  login: {},
  toolbar: {
    paddingLeft: theme.spacing(1),
  },
  dashboard: {
    cursor: "pointer",
    fontSize: '1.1rem',
    marginRight: theme.spacing(2),
    '&:hover': {
      textShadow: '0px 0px 10px #DDDDDD',
    },
  },
  button: {
    background: theme.overrides.MuiAppBar.colorPrimary.backgroundColor,
    color: theme.overrides.MuiAppBar.colorPrimary.color,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    marginLeft: theme.spacing(1),
  },
}));

const Header = ( props ) => {

  const classes = useStyles();

  const { text } = React.useContext( LanguageContext );
  const user = React.useContext( UserContext );
  const theme = React.useContext( KijkMeeThemeContext );
  let history = useHistory();
  const eventRouteMatch = useRouteMatch({
    path: "/:event_guid",
    strict: true
  })

  const [ contactOpen, setContactOpen ] = React.useState(false);
  const [ isEvent, setIsEvent ] = React.useState(false);

  const gotoInfo = () => history.push("/info");
  const gotoContact = () => setContactOpen(true);
  const gotoDashboard = () => history.push("/manage/events");
  const gotoUsers = () => history.push("/dashboard/users");
  const gotoServers = () => history.push("/dashboard/servers");
  // const gotoPayments = () => history.push("/dashboard/payments");

  let defaultLogo = true;
  if( theme.logo !== kijkmeelogo ){
    defaultLogo = false;
  }

  React.useEffect( () => {
    if( eventRouteMatch && eventRouteMatch.isExact && eventRouteMatch.url !== "/info" ) {
      setIsEvent(true);
    } else {
      setIsEvent(false);
    }
  }, [eventRouteMatch]);

  return(
    <>
    <AppBar position="static" className={classes.root}>
    <Container className={classes.viewEventContainer} disableGutters>
      <Toolbar className={classes.toolbar}>

        { (isEvent) ?
          (<img src={theme.logo} alt="Logo" className={classes.logo} />)
          :
          (<Link to="/"><img src={theme.logo} alt="Logo" className={classes.logo} /></Link>)
        }

        <Typography variant="h6" className={classes.pageTitle}></Typography>

        { defaultLogo &&
          <Hidden smDown>


            { ( !isEvent ) &&
              <>
                <Button className={classes.button} onClick={gotoInfo}>
                  Informatie
                </Button>
                <Button className={classes.button} onClick={gotoContact}>
                  Contact
                </Button>
                <ContactUs open={contactOpen} onClose={e=>setContactOpen(false)} />
              </>
            }

            { user.token && (user.role === "Administrator" || user.role === "Customer") &&
              <Button className={classes.button} onClick={gotoDashboard}>
                {text.header.dashboard}
              </Button>
            }

            {/* { user.role === 'Administrator' &&
              <Button className={classes.button} onClick={gotoPayments}>
                {text.header.payments || "Transacties"}
              </Button>
            } */}

            { user.role === 'Administrator' &&
              <Button className={classes.button} onClick={gotoUsers}>
                {text.header.users}
              </Button>
            }

            { user.role === 'Administrator' &&
              <Button className={classes.button} onClick={gotoServers} /*startIcon={<StorageIcon />}*/>
                {text.header.servers}
              </Button>
            }

          </Hidden>
        }

        <LanguageSelectorButton />

        <Hidden smDown>
          <UserAccount />
        </Hidden>

        <Hidden mdUp>
          <MenuButton />
        </Hidden>

      </Toolbar>
    </Container>
    </AppBar>

    </>
  );
}

export default Header;
