/*


*/

import React from 'react';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

import kijkmeelogo from '../static/header_logo.png';

const hexToRgb = (hex, alpha) => {
  if(alpha && isNaN(alpha) && alpha.toString().indexOf('.') === -1 ) {
    alpha = undefined;
  }

  let r = 0;
  let g = 0;
  let b = 0;

  // convert
  if( hex.length === 7 ) {
    r = "0x" + hex[1] + hex[2];
    g = "0x" + hex[3] + hex[4];
    b = "0x" + hex[5] + hex[6];
  }
  else if( hex.length === 4){
    r = "0x" + hex[1] + hex[1];
    g = "0x" + hex[2] + hex[2];
    b = "0x" + hex[3] + hex[3];
  }
  else {
    return 'rgb(0,0,0)';
  }

  if( alpha ) {
    return `rgb(${+r}, ${+g}, ${+b}, ${alpha})`;
  }
  else {
    return `rgb(${+r}, ${+g}, ${+b})`;
  }
}


const default_theme = () => createTheme({
  palette: {
    primary: {
      main: '#1a7ea1'
    },
    secondary: {
      main: '#e55247',
    },
    background: {
      default: hexToRgb('#b2ccd2', '1'),
    },
    action: {
      active: hexToRgb('#1a7ea1'),
      hover: hexToRgb('#1a7ea1', '0.1'),
      focus: hexToRgb('#1a7ea1', '0.12' ),
      selected: hexToRgb('#1a7ea1', '0.55'),
    },
  },
  overrides:{
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#343a40',
        color: '#C1C1C1',//'#f8f9fa',
      }
    },
    MuiCssBaseline: {
      '@global': {
         body: {
           background: 'linear-gradient(90deg, rgba(80,142,184,0.5) 0%, rgba(178,204,210,1) 15%, rgba(178,204,210,1) 85%, rgba(80,142,184,0.5) 100%)',
           backgroundRepeat: "no-repeat",
           backgroundAttachment: "fixed",
        },
      },
    },
  }
});

const KijkMeeThemeContext = React.createContext();

const KijkMeeThemeProvider = ( props ) => {

  const [ currentTheme, setCurrentTheme ] = React.useState( default_theme() );
  const [ logo, setLogo ] = React.useState( kijkmeelogo );

  const setTheme = React.useCallback( ( appbarBgColor, appbarTextColor, primaryColor, secondaryColor, backgroundColor ) => {

    const newTheme = createTheme({
      palette: {
        primary: {
          main: primaryColor || '#1a7ea1',
        },
        secondary: {
          main: secondaryColor || '#e55247',
        },
        background: {
          default: backgroundColor || '#FEFEFE',
        },
      },
      overrides:{
        MuiAppBar: {
          colorPrimary: {
            backgroundColor: appbarBgColor || '#343a40', //'#343a40',
            color: appbarTextColor || '#f8f9fa',
          }
        },
        MuiCssBaseline: {
          '@global': {
             body: {
               //background: backgroundColor || 'linear-gradient(306deg, rgba(145,204,204,1) 0%, rgba(42,95,132,1) 81%);',
               background: backgroundColor || 'linear-gradient(90deg, rgba(80,142,184,0.5) 0%, rgba(178,204,210,1) 3%, rgba(178,204,210,1) 97%, rgba(80,142,184,0.5) 100%)',
               backgroundRepeat: "no-repeat",
               backgroundAttachment: "fixed",
            },
          },
        },
      }
    });
    setCurrentTheme(newTheme);
  }, [] );

  const setDefault = React.useCallback( () => {
    setCurrentTheme( default_theme() );
    setLogo( kijkmeelogo );
  }, [] );

  return(
    <KijkMeeThemeContext.Provider value={ {setTheme, setDefault, logo, setLogo} }>
      <ThemeProvider theme={currentTheme}>
        {props.children}
      </ThemeProvider>
    </KijkMeeThemeContext.Provider>
  );
}

export { KijkMeeThemeContext, KijkMeeThemeProvider };
