/*

  AySay Broadcast bv.

*/

import React from 'react';
import ReactGA from 'react-ga';
import Settings from '../config';

export const getScreenSize = () => {
  let width = (window.screen.width * window.devicePixelRatio).toString();
  let height = (window.screen.height * window.devicePixelRatio).toString();
  return width + "x" + height;
}

export const usePageView = () => {

  // ga enabled
  const [ gaEnabled, setGaEnabled ] = React.useState(false);

  //
  const enableGA = React.useCallback( () => {
    ReactGA.initialize('UA-159228041-2');
    setGaEnabled(true);
  }, []);

  // register pageview in our backend
  const registerPageView = React.useCallback( ( session, pathname ) => {

    // register google
    if( gaEnabled ) {
      ReactGA.pageview( pathname );
    }

    // register kijk-mee api
    let url = Settings.api.url + "/stats/pageview";
    fetch( url, {
      method: "POST",
      cache: 'no-cache',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "platform": "kijk-mee",
        "time": new Date().getTime(),
        "visitor": session || "",
        "path": pathname || "",
        "ua": navigator.userAgent || "",
        "screensize": getScreenSize(),
      }),
    })
    .catch( err => {
      console.error(err);
    });
  }, [gaEnabled]);

  return {
    registerPageView,
    gaEnabled,
    enableGA,
  }
}
export default usePageView;
