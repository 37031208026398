/*


    By Aysay B.V.


*/

import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '../../providers/Language';
import useUsers from '../../providers/useUsers';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

//
const useStyles = makeStyles((theme) => ({
  root: {},
  input: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2),
  },
}));

//
export const UserProfile = props => {

  const { show, setShow, userid } = props;

  if( !userid ) {
    throw new Error("UserProfile - missing a userid")
  }

  const { text } = React.useContext(LanguageContext);
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { loadUser, updateUser } = useUsers();

  const [ user, setUser ] = React.useState();

  const updateUserObject = React.useCallback( (newUser) => {
    let defaultUser = {id: undefined,name: "",company: "",email: "",phone: "",language: "nl",locale: "nl",role: undefined};
    setUser({...defaultUser, ...user, ...newUser});
  }, [user]);

  const handleUpdate = React.useCallback( () => {
    updateUser(userid, user).then(e=>setShow(false)).catch(console.error);
  }, [updateUser, userid, user, setShow]);

  React.useEffect(() => {
    if( !user || user.id !== userid ) {
      loadUser(userid).then(updateUserObject).catch(console.error)
    }
  }, [user, userid, loadUser, updateUserObject]);

  return(
    <>
      <Dialog open={show} fullScreen={fullScreen} onClose={e=>setShow(false)}>
          <DialogTitle className={classes.root}>{text.users.myAccount}</DialogTitle>
          <DialogContent>
            { user &&
              <form autoComplete="off">
                <TextField
                  label={text.users.name}
                  value={user.name || ""}
                  onChange={e=>updateUserObject({name: e.target.value})}
                  required
                  fullWidth
                  className={classes.input}
                  variant="outlined"
                />
                <TextField
                  label={text.users.email}
                  value={user.email || ""}
                  onChange={e=>updateUserObject({email: e.target.value})}
                  required
                  fullWidth
                  disabled
                  className={classes.input}
                  variant="outlined"
                />
                <TextField
                  label={text.users.phone}
                  value={user.phone || ""}
                  onChange={e=>updateUserObject({phone: e.target.value})}
                  required
                  fullWidth
                  className={classes.input}
                  variant="outlined"
                />
                <TextField
                  label={text.users.company}
                  value={user.company || ""}
                  onChange={e=>updateUserObject({company: e.target.value})}
                  required
                  fullWidth
                  className={classes.input}
                  variant="outlined"
                />
              </form>
            }
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={e=>setShow(false)}
              className={classes.button}
            >
              {text.users.cancelButton}
              </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={e=>handleUpdate()}
            >
              {text.users.updateButton}
            </Button>
          </DialogActions>
      </Dialog>
    </>
  );
}
export default UserProfile;
