/*

  Copyright AySay Broadcast b.v.

*/

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
//import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

// import
//import homeLogo from '../static/home_logo_with_shadow.webp';
import homeLogo from '../static/home_logo.png';
import url_image from '../static/01-url.png';
import secure_image from '../static/02-beveiliging.png';
import servers_image from '../static/03-servers.png';
import interaction_image from '../static/04-interactie.png';
import technical_image from '../static/05-techniek.png';
import gaming_image from '../static/06-onlinespellen.png';
import personal_image from '../static/07-persoonlijk.png';
import company_image from '../static/08-bedrijf.png';

import { LanguageContext } from '../providers/Language';
import ToLiveStream from './ToLiveStream';

// styles
const useStyles = makeStyles((theme) => ({
  logo: {
    maxWidth: '100%',
    //boxShadow: theme.shadows[5]
  },
  bigText: {
    fontSize: '1.4em',
  },
  promo: {
    boxShadow: theme.shadows[10],
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  promoText:{},
  promoMedia: {
    height: 140,
  }
}));

const Home = ( props ) => {

  const classes = useStyles();
  const { text } = React.useContext( LanguageContext );

  return(
    <>
      <Container>
        <Grid container spacing={2} sx={{mb: 5}}>
          <Grid item xs={12}>
            <ToLiveStream />
          </Grid>

          <Grid item sm={4}>
            <img src={homeLogo} alt="Logo" className={classes.logo} />
          </Grid>

          <Grid item sm={8}>
            <Typography variant="h3" component="h1" color="primary">{text.home.header}</Typography>

            <br />
            <br />
            <Typography variant="body1" className={classes.bigText} color="textSecondary">{text.home.subHeader}</Typography>
            <br />
            <Typography variant="body1" className={classes.bigText} color="textSecondary">{text.home.contact}</Typography>
            <br />
            <Grid container spacing={2}>
              <Grid item>
                <Fab variant="extended" color="primary" aria-label="email us" href="mailto:info@kijk-mee.nl">
                  <EmailIcon />
                  &nbsp;&nbsp;info@kijk-mee.nl
                </Fab>
              </Grid>
              {/*
              <Grid item>
                <Fab variant="extended" color="primary" aria-label="call us" href="tel:0031534782135">
                  <PhoneIcon />
                  &nbsp;&nbsp;+31 (0)53 478 21 35
                </Fab>
              </Grid>
              */}
            </Grid>
          </Grid>

          <Grid item xs={12} container spacing={2} justifyContent="center" alignItems="stretch">
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Card className={classes.promo}>
                <CardActionArea>
                  <CardMedia
                    className={classes.promoMedia}
                    image={url_image}
                    title={text.home.block1.header}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {text.home.block1.header}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {text.home.block1.text}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary" href="/info">
                    {text.home.moreInfo}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Card className={classes.promo}>
                <CardActionArea>
                  <CardMedia
                    className={classes.promoMedia}
                    image={secure_image}
                    title={text.home.block2.header}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {text.home.block2.header}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {text.home.block2.text}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary" href="/info">
                    {text.home.moreInfo}
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Card className={classes.promo}>
                <CardActionArea>
                  <CardMedia
                    className={classes.promoMedia}
                    image={servers_image}
                    title={text.home.block3.header}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {text.home.block3.header}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {text.home.block3.text}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary" href="/info">
                    {text.home.moreInfo}
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Card className={classes.promo}>
                <CardActionArea>
                  <CardMedia
                    className={classes.promoMedia}
                    image={interaction_image}
                    title={text.home.block4.header}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {text.home.block4.header}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {text.home.block4.text}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary" href="/info">
                    {text.home.moreInfo}
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs={12}>
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Card className={classes.promo}>
                <CardActionArea>
                  <CardMedia
                    className={classes.promoMedia}
                    image={technical_image}
                    title={text.home.block5.header}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {text.home.block5.header}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {text.home.block5.text}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary" href="/info">
                    {text.home.moreInfo}
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Card className={classes.promo}>
                <CardActionArea>
                  <CardMedia
                    className={classes.promoMedia}
                    image={gaming_image}
                    title={text.home.block6.header}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {text.home.block6.header}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {text.home.block6.text}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary" href="/info">
                    {text.home.moreInfo}
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Card className={classes.promo}>
                <CardActionArea>
                  <CardMedia
                    className={classes.promoMedia}
                    image={personal_image}
                    title={text.home.block7.header}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {text.home.block7.header}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {text.home.block7.text}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary" href="/info">
                    {text.home.moreInfo}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Card className={classes.promo}>
                <CardActionArea>
                  <CardMedia
                    className={classes.promoMedia}
                    image={company_image}
                    title={text.home.block8.header}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {text.home.block8.header}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {text.home.block8.text}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary" href="/info">
                    {text.home.moreInfo}
                  </Button>
                </CardActions>
              </Card>
            </Grid>

          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Home;
