/*


  App Settings


*/


const settings = {
  api: {
    url: "https://stream3.kijk-mee.nl"
    //url: 'http://localhost:12345'
  },
  stream: {
    server: "rtmps://live.kijk-mee.nl/livestream",
    backupServer: "rtmp://live.kijk-mee.nl:1935/livestream"
  }
}
export default settings;
