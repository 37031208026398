/*

  AySay Broadcast bv.

*/

import React from 'react';
import Settings from '../config';
import { UserContext } from './User';

export const useUsers = () => {

  const { token, session } = React.useContext( UserContext );
  const [ loading, setLoading ] = React.useState(false);
  const [ error, setError ] = React.useState();
  const [ users, setUsers ] = React.useState();
  const [ roles, setRoles ] = React.useState();

  const loadRoles = React.useCallback( async () => {
    if( !token ){
      setRoles( [] );
      return;
    }
    setLoading( true );
    const url = Settings.api.url + '/roles';
    fetch( url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        'Authorization': "Bearer " + token
      }
    })
    .then( response => {
      if( response.status > 199 && response.status < 300 ){
        return response.json();
      }
      return;
    })
    .then( data => {
      if( data ) {
        setRoles( data.roles );
      }
      else {
        setRoles( [] );
      }
      setLoading( false );
    })
    .catch( err => {
      console.error("loadRoles() error: ", err );
      setLoading( false );
    })
  }, [token]);

  const loadUsers = React.useCallback( async () => {
    if( !token ) {
      setUsers( [] );
      return;
    }
    setLoading( true );
    setError( undefined );
    const url = Settings.api.url + "/users";
    fetch( url, {
      method: 'GET',
      mode: 'cors',
      credentials: 'omit',
      headers: { 'Authorization': "Bearer " + token }
    })
    .then( response => {
      if( response.status > 199 && response.status < 300 ) {
        return response.json();
      }
      return;
    })
    .then( data => {
      if( data && data.users ) {
        setUsers( data.users );
        setLoading( false );
      }
      else {
        setUsers( [] );
        setLoading( false );
      }
    })
    .catch( err => {
      setLoading( false );
      setUsers( [] );
      console.error("loadUsers() error: ", err );
    });
  }, [token]);

  const loadUser = React.useCallback( ( user_id ) => {
    return new Promise( ( resolve, reject ) => {
      if( !user_id || !token ){
        console.error("no user_id or token")
        reject(undefined);
      }
      setLoading( true );
      setError( undefined );
      const url = Settings.api.url + "/users/" + user_id;
      fetch( url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        headers: {
          'Authorization': "Bearer " + token
        }
      })
      .then( response => {
        if( response.status > 199 && response.status < 300 ){
          return response.json();
        }
        return false;
      })
      .then( data => {
        if( data ) {
          setLoading( false );
          resolve( data.user );
        }
        else {
          setLoading( false );
          resolve( undefined );
        }
      })
      .catch( err => {
        setLoading( false );
        reject( err );
      });
    });

  }, [token]);

  const createUser = React.useCallback( async ( newUser ) => {
    if( !newUser || !token ){ return; }
    setLoading( true );
    setError( undefined );
    const url = Settings.api.url + '/users';
    fetch( url, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token
      },
      body: JSON.stringify( {user: newUser} )
    })
    .then( response => {
      setLoading( false );
      if( response.status > 199 && response.status < 300 ){
        return true;
      }
      return false;
    })
    .catch( err => {
      setLoading( false );
      setError("createUser() error: ",  err );
      console.error( err );
    })
  }, [token]);

  const deleteUser = React.useCallback( async ( id ) => {
    if( !id || !token ){ return; }
    setLoading( true );
    setError( undefined );
    if( ! window.confirm("Zeker weten?") ) {
      setLoading( false );
      return;
    }
    const url = Settings.api.url + '/users/' + id;
    fetch( url, {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'omit',
      headers: { 'Authorization': "Bearer " + token }
    })
    .then( response => {
      if( response.status > 199 && response.status < 300 ){
        setLoading( false );
        loadUsers();
      }
      else {
        setLoading( false );
        throw new Error("User delete failed: " + response.status);
      }
    })
    .catch( err => {
      setLoading( false );
      setError( err );
      console.error( err );
    });
  }, [loadUsers, token]);

  const updateUser = React.useCallback( ( user_id, modifiedUser ) => {
    return new Promise( (resolve, reject) => {

      if( !modifiedUser || !token ){ reject(400);}
      setLoading( true );
      setError( undefined );
      const url = Settings.api.url + '/users/' + user_id ;
      fetch( url, {
        method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        },
        body: JSON.stringify({user: modifiedUser })
      })
      .then( response => {
        if( response.status === 409 ) {
          // email exists
          console.error("email already exists" );
          reject( "email already in use" );
        }
        else if( response.status !== 201 && response.status !== 200 ) {
          reject( "api return an error: " + response.status );
        }
        else {
          return response.json();
        }
      })
      .then( data => {
        if( data ) {
          setLoading( false );
          resolve( data.user );
        }
        else {
          setLoading(false);
          reject( undefined );
        }
      })
      .catch( err => {
        console.error( err );
        setError( err );
        setLoading(false);
        reject( undefined );
      });
    });
  }, [token]);

  const updatePassword = React.useCallback( ( user_id, newPassword, oldPassword ) => new Promise( (resolve, reject) => {
    setLoading( true );
    setError( undefined );
    const url = Settings.api.url + "/users/" + user_id + "/changepassword";
    const post_body = {
      "newPassword": newPassword,
      "oldPassword": oldPassword
    };
    if( !token ) {
      resolve(false);
      return false;
    }
    fetch( url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token
      },
      body: JSON.stringify( post_body )
    })
    .then( response => {
      if( response && response.status === 200 ) {
        return response.json();
      }
      return;
    })
    .then( data => {
      setLoading( false );
      if( data ) {
        resolve(true);
      }
      resolve(false);
    })
    .catch( err => {
      setLoading( false );
      setError( err );
      console.error( err );
      resolve(false);
    })
  }), [token] );

  const getUsage = React.useCallback( async ( user_id ) => {
    return new Promise( ( resolve, reject ) => {
      if( !user_id || !token ){
        console.error("no user_id or token")
        reject(undefined);
      }
      setLoading( true );
      setError( undefined );
      const url = Settings.api.url + "/users/" + user_id + "/usage";
      fetch( url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        headers: {
          'Authorization': "Bearer " + token
        }
      })
      .then( response => {
        if( response.status > 199 && response.status < 300 ){
          return response.json();
        }
        return false;
      })
      .then( data => {
        if( data ) {
          setLoading( false );
          resolve( data.usage );
        }
        else {
          setLoading( false );
          resolve( undefined );
        }
      })
      .catch( err => {
        setLoading( false );
        reject( err );
      });
    });
  }, [token]);

  const signUp = React.useCallback( (newUser) => new Promise( (resolve, reject) => {
    // everything we need present?
    if( !newUser || !newUser.email || !newUser.name || !newUser.password ) {
      resolve(false);
      return;
    }

    setLoading( true );
    setError( undefined );

    newUser.session = session;

    // options
    const url = Settings.api.url + '/users/signup';
    const options = {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify( {user: newUser} )
    };

    // do request
    fetch( url, options )
    .then( response => {
      setLoading( false );
      if( response.status === 200 ){
        resolve(true);
        return;
      }
      resolve(false);
    })
    .catch( err => {
      setLoading( false );
      setError("signup error: ",  err );
      console.error( err );
      resolve(false);
    })
  }), [session]);

  const verifySignUp = React.useCallback( (code, user_id) => new Promise( (resolve, reject ) => {

    if( !code || !user_id ) {
      resolve(false);
      return;
    }

    setLoading( true );
    setError( undefined );

    const url = Settings.api.url + `/users/${user_id}/verify-signup`;
    const options = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify( {code: code, session: session} )
    };

    fetch( url, options )
    .then( response => {
      setLoading( false );
      if( response.status === 200 ){
        resolve(true);
        return;
      }
      resolve(false);
    })
    .catch( err => {
      console.error( err );
      setLoading( false );
      setError( "verify signup error" + err.toString());
      resolve( false );
    });
  }), [session]);

  return {
    loading,
    error,
    users,
    roles,
    loadRoles,
    loadUsers,
    loadUser,
    deleteUser,
    updateUser,
    createUser,
    updatePassword,
    getUsage,
    signUp,
    verifySignUp
  };
}
export default useUsers;
